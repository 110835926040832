import { History } from "history";
import moment from "moment";
import download from "downloadjs";
import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import {
  createInfoCell,
  createClickableCell,
  createSelectCell,
  createCellWithChildren,
} from "../../utilities/CreateCell";
import { createRow } from "../../utilities/CreateRow";
import { toastFail, toastInfo } from "../../utilities/toast";
import { UserRole, ReturnRequestStatus } from "../../utilities/Enums";
import {
  isAdmin,
  isWholesalerUser,
  isNormalUser,
  isRoleIncluded
} from "../../utilities/checkUserRole";

//TODO: Implement interfaces and types
export const createReturnsRows = (
  data: any,
  history: History,
  userRole: string,
  showAllReturns: boolean,
  setOpenConfirmDelete: any,
  setToBeDeletedObject: any,
  updateStatus: any,
  statusOptions: Array<string>,
  renderChildren: any,
  getMethod: (url: any, payload?: any) => Promise<any>
) => {
  const rows: Array<TableRowProps> = [];
  data.forEach((entry: any) => {
    const currentStatus = entry.returnRequest?.pharmacy?.enabled || false;
    const row: TableRowProps = createRow(
      createNumberCell(entry),
      createCreatedAtCell(entry),
      createPharmacyCell(entry, userRole, showAllReturns),
      createUserCell(entry, userRole, showAllReturns),
      createItemsCell(entry, userRole, history),
      createStatusCell(
        entry,
        userRole,
        statusOptions,
        updateStatus,
        currentStatus
      ),
      createUpsShipmentsCell(entry, userRole, history, renderChildren),
      createReportsCell(entry, history),
      createServiceTypeCell(entry),
      createPreferredDateCell(entry),
      createGenerateExcelCell(entry, userRole, getMethod),
      createEditCell(entry, userRole, history, currentStatus),
      createRemoveCell(
        entry,
        userRole,
        setOpenConfirmDelete,
        setToBeDeletedObject,
        currentStatus
      )
    );
    rows.push(row);
  });
  return rows;
};
const createNumberCell = (entry: any) => {
  return createInfoCell(entry.returnRequest.id);
};

const createCreatedAtCell = (entry: any) => {
  return createInfoCell(
    moment(entry.returnRequest.createdAt).format("YYYY-MM-DD")
  );
};

const createPharmacyCell = (
  entry: any,
  userRole: string,
  showAllReturns: boolean
) => {
  if (isAdmin(userRole) && showAllReturns) {
    return createInfoCell(entry.returnRequest.pharmacy?.doingBusinessAs!);
  }
};

const createUserCell = (
  entry: any,
  userRole: string,
  showAllReturns: boolean
) => {
  if (isAdmin(userRole) && showAllReturns) {
    return createInfoCell(
      capitalize(entry.returnRequest.pharmacy?.user?.username!)
    );
  }
};

const capitalize: any = (str: string): string => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

const createItemsCell = (entry: any, userRole: string, history: History) => {
  if (!isRoleIncluded([UserRole.WholesalerUser], userRole)) {
    return createClickableCell(() => {
      if (isRoleIncluded([UserRole.User], userRole)) {
        history.push(
          `/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/itemsPage`
        );
      } else {
        history.push(
          `/admin/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/items`
        );
      }
    }, entry.numberOfItems);
  }
};

const createStatusCell = (
  entry: any,
  userRole: string,
  statusOptions: Array<string>,
  updateStatus: any,
  pharmacyEnabledStatus: boolean
) => {
  if (isAuthorizedToUpdateStatus(userRole, entry) && pharmacyEnabledStatus) {
    const statusOptionsBasedOnUser = getStatusOptionsBasedOnUser(
      statusOptions,
      userRole
    );
    return createSelectCell(
      statusOptionsBasedOnUser,
      entry.returnRequest.returnRequestStatus,
      (event: any) => {
        updateStatus({
          selectedOption: event.target.value,
          pharmacyId: entry.returnRequest.pharmacy.id,
          requestId: entry.returnRequest.id,
        });
      }
    );
  }
  return createInfoCell(entry.returnRequest.returnRequestStatus);
};

const isAuthorizedToUpdateStatus = (userRole: string, entry: any) => {
  return (
    isAdmin(userRole) ||
    (isNormalUser(userRole) && isNormalUserAuthorizedToUpdateStatus(entry))
  );
};

const isNormalUserAuthorizedToUpdateStatus = (entry: any) => {
  return (
    entry.returnRequest.returnRequestStatus === ReturnRequestStatus.Preparing ||
    entry.returnRequest.returnRequestStatus === ReturnRequestStatus.InTransit
  );
};

const getStatusOptionsBasedOnUser = (
  statusOptions: Array<string>,
  userRole: string
) => {
  let mappedStatusOptions = statusOptions?.map((option: string) => {
    return { value: option, label: option };
  });
  if (isAdmin(userRole)) {
    return mappedStatusOptions;
  }
  return mappedStatusOptions?.slice(0, 2);
};

const createUpsShipmentsCell = (
  entry: any,
  userRole: string,
  history: History,
  renderChildren: any
) => {
  if (!isWholesalerUser(userRole)) {
    if (noShipmentsYet(entry)) {
      return createCellWithChildren(renderChildren(entry));
    }
    return createClickableCell(() => {
      history.push(
        `/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/shipments`
      );
    }, entry.numberOfShipments);
  }
};

const noShipmentsYet = (entry: any) => {
  return entry.numberOfShipments === 0;
};

const createReportsCell = (entry: any, history: History) => {
  return createClickableCell(() => {
    history.push(
      `/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/reports`
    );
  }, entry.numberOfReports);
};

const createServiceTypeCell = (entry: any) => {
  return createInfoCell(entry.returnRequest.serviceType);
};

const createPreferredDateCell = (entry: any) => {
  const preferredDate = entry.returnRequest.preferredDate;
  if (!preferredDate) {
    return createInfoCell("");
  }
  const dateObj = new Date(preferredDate);
  const formattedDate = dateObj.toLocaleDateString("en-US", {
    timeZone: "UTC",
  });
  return createInfoCell(formattedDate);
};

const createGenerateExcelCell = (
  entry: any,
  userRole: string,
  getMethod: any
) => {
  if (isRoleIncluded([UserRole.Admin, UserRole.Warehouse], userRole)) {
    return createClickableCell(async () => {
      toastInfo(
        "Generating Excel Report for return request " + entry.returnRequest.id
      );
      let response = await getMethod(
        `/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/excel`,
        {
          responseType: "blob",
        }
      );
      if (response.response && response.response.status >= 400) {
        toastFail(
          "Can not generate a report for a request with all 'Pending' items!"
        );
      } else {
        download(
          response.data,
          `${entry.returnRequest.pharmacy.doingBusinessAs}-${entry.returnRequest.id}.xlsx`,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      }
    }, "Generate Excel");
  }
};

const createEditCell = (
  entry: any,
  userRole: string,
  history: History,
  pharmacyEnabledStatus: boolean
) => {
  if (pharmacyEnabledStatus) {
    return createClickableCell(() => {
      if (isRoleIncluded([UserRole.User], userRole)) {
        history.push(
          `/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/add`
        );
      } else {
        history.push(
          `/admin/pharmacies/${entry.returnRequest.pharmacy.id}/returnrequests/${entry.returnRequest.id}/add`
        );
      }
    }, "Edit");
  } else {
    return null;
  }
};

const createRemoveCell = (
  entry: any,
  userRole: string,
  setOpenConfirmDelete: any,
  setToBeDeletedObject: any,
  pharmacyEnabledStatus: boolean
) => {
  if (pharmacyEnabledStatus) {
    return createClickableCell(() => {
      setOpenConfirmDelete(true);
      setToBeDeletedObject({
        id: entry.returnRequest.id,
        pharmacyId: entry.returnRequest.pharmacy.id,
      });
    }, "Remove");
  }
};

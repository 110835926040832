import React, { useCallback, useEffect, useState, useRef } from "react";
import { Container } from "@material-ui/core";
import { CallToActionButton } from "../../atoms/callToActionButton/CallToActionButton";
import TableOrganism from "../../organisms/Table/TableOrganism";
import { createHeaderRow } from "../../utilities/CreateHeaderRow";
import {
  useStyles,
  CardsWrapper,
  ImageWrapper,
  ItemEntry,
  Product,
  ButtonWrapper,
  ButtonsWrapper,
} from "./AddItemPageStyles";
import { default as AddItemImage } from "../../assets/add-Item.jpg";
import FormMolecule from "../../molecules/Form/FormMolecule";
import { itemEntry, productDetails } from "./AddItemPageData";
import { FieldValues, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TableRowProps } from "../../molecules/TableRow/TableRowAtom";
import { createItemsRows } from "./AddItemTable";
import { fetchItem } from "../../network/Services";
import * as strings from "../../utilities/strings";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import IPage from "../../config/interfaces/page";
import {
  BigRedButton,
  ButtonVersion,
} from "../../atoms/BigRedButton/BigRedButtonAtom";
import {
  TextButton,
  TextButtonVersion,
} from "../../atoms/TextButton/TextButton";
import { useLoaderDispatch, useLoaderState } from "../../context/LoaderContext";
import Loader from "../../atoms/Loader/LoaderAtom";
import useApiService from "../../utilities/useApiService";
import { largeButton } from "../../utilities/style";
import { useAuthState } from "../../context/AuthContext";
import { isRoleIncluded } from "./../../utilities/checkUserRole";
import { startLoading, stopLoading } from "../../context/actions/loaderActions";
import { ReturnRequestServiceType, UserRole } from "../../utilities/Enums";
import { Parser } from "../../utilities/DataMatrixParser/Parser";
import { fields } from "../../utilities/DataMatrixParser/constants";
import { extractNDC } from "../../utilities/DataMatrixParser/extractNDC";
import { formatDate } from "../../utilities/DataMatrixParser/formatDate";
import { toastInfoDelayedClose } from "../../utilities/toast";
import { formatNdc, isNdcValid } from "../../utilities/ndc/ndcUtils";
import { ItemsTable } from "../../network/models/TableInterfaces";

export interface AddItemPageProps {}

export const AddItemPage: React.FC<
  AddItemPageProps & RouteComponentProps<any> & IPage
> = (props) => {
  const ndcRef = useRef<HTMLInputElement>(null);
  const { user } = useAuthState();
  const userRole = user!.role;
  const { deleteMethod, getMethod, postMethod, putMethod } = useApiService();
  const itemsRef: any = useRef(null);
  const history = useHistory();
  const params: any = useParams();
  const queryClient = useQueryClient();
  const classes = useStyles(props);
  const { control, handleSubmit, watch, setValue, reset } = useForm();
  const [newNdc, setNewNdc] = useState("");
  const [parser] = useState(new Parser(fields));
  const [output, setOutput] = useState<string[]>([]);
  const [price, setPrice] = useState(0);
  const [editingItem, setEditingItem] = useState<ItemsTable | null>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  let ndc: string = watch("ndc");

  //TODO: Clear fields in a better way, possibly resetField
  const clearProductInfoFields = useCallback(() => {
    setValue("manufacturer", "");
    setValue("pkgSize", "");
    setValue("controlledSubstanceCode", "");
    setValue("description", "");
    setValue("strength", "");
    setValue("dosage", "");
  }, [setValue]);
  const clearFormFields = useCallback(() => {
    reset({
      ndc: "",
      fullQty: "",
      partialQty: "",
      expDate: "",
      lot: "",
      description: "",
      manufacturer: "",
      strength: "",
      dosage: "",
      pkgSize: "",
      price:"",
      gtin14: "",
      serialNumber: "",
      quantity: "",
    });
    setEditingItem(null);
  }, [reset]);

  useEffect(() => {
    if (ndc && ndc.length >= 9) {
      clearProductInfoFields();
      setNewNdc(ndc);
    } else if (!ndc) {
      setNewNdc("");
      parser.resetParser();
      clearFormFields();
      setOutput(() => []);
    }
  }, [ndc, setValue, parser, clearFormFields, clearProductInfoFields]);

  const loaderDispatch = useLoaderDispatch();
  const { loading } = useLoaderState();
  const { data: productData } = useQuery(
    [strings.addItemQueryKey, newNdc],
    () => isNdcValid(newNdc) && fetchItem(formatNdc(newNdc), loaderDispatch)
  );

  useEffect(() => {
    if (productData) {
      setValue("manufacturer", productData.manufacturer);
      setValue("pkgSize", productData.package_size);
      setValue("controlledSubstanceCode", productData.Controlled_Substance_Code);
      setValue("description", productData.item_name);
      setValue("strength", productData.strength);
      setValue("dosage", productData.dosage);
      setValue("ndc", productData.product_ndc);
    }
  }, [productData, setValue]);

  const headerTitles = [
    "NDC",
    "Description",
    "Manufacturer",
    "Strength",
    "Dosage",
    "Lot #",
    "Expiration Date",
    "Pkg Size",
    "CSC",
    "Full Quantity",
    "Partial Quantity",
    "Extended Price",
    "Extended Unit Price",
    "  ",
    "  ",
  ];

  const fetchItems = async () => {
    setLoadingItems(true);
    const response = await getMethod(
      `/pharmacies/${params.pharmacyId}/returnrequests/${params.requestId}/items`
    );
    setLoadingItems(false);
    return response.data;
  };
  const [loadingReturn, setLoadingReturn] = useState(false);
  const fetchReturnRequest = async () => {
    setLoadingReturn(true);
    const response = await getMethod(
      `/pharmacies/${params.pharmacyId}/returnrequests/${params.requestId}`
    );
    setLoadingReturn(false);
    return response.data;
  };

  const { data: items } = useQuery("items", fetchItems);
  const { data: returnRequest }: any = useQuery("returnRequest", () =>
    history.location.pathname.indexOf("admin") === -1
      ? fetchReturnRequest()
      : {}
  );
  const [loadingItems, setLoadingItems] = useState(false);

  const createReturnRequestApi = async () => {
    startLoading(loaderDispatch);
    const payload = props.location.state || {
      serviceType: ReturnRequestServiceType.ExpressService,
    };
    const response = await postMethod(
      `/pharmacies/${params.pharmacyId}/returnrequests`,
      payload
    );
    stopLoading(loaderDispatch);
    return response.data;
  };

  const addItem = async (returnRequestId: number, data: FieldValues) => {
    const formattedNdc = formatNdc(data.ndc);

    await postMethod(
      `/pharmacies/${params.pharmacyId}/returnrequests/${returnRequestId}/items`,
      {
        ndc: formattedNdc,
        description: data.description,
        name: "name",
        manufacturer: data.manufacturer,
        packageSize: data.pkgSize,
        gtin14: data.gtin14,
        serialNumber: data.serialNumber,
        controlledSubstanceCode: data.controlledSubstanceCode,
        requestType: "typeofrequest",
        fullQuantity: data.quantityType === "full" ? data.quantity : "0",
        partialQuantity: data.quantityType === "partial" ? data.quantity : "0",
        strength: data.strength,
        dosage: data.dosage,
        status: "PENDING",
        expirationDate: data.expDate,
        lotNumber: data.lot,
      }
    )
      .then((response) => {
        queryClient.setQueryData(
          "items",
          items ? [...items, response.data] : [response.data]
        );
        if (!params.requestId) {
          history.push(
            `${isRoleIncluded([UserRole.Admin, UserRole.Warehouse, UserRole.FieldProcessor], userRole) ? "/admin" : ""}/pharmacies/${
              params.pharmacyId
            }/returnrequests/${returnRequestId}/add`
          );
        }
      })
      .then(() => parser.resetParser());
  };


  const updateItem = async (returnRequestId: number, data: FieldValues) => {
    const formattedNdc = formatNdc(data.ndc);

    await putMethod(
      `/pharmacies/${params.pharmacyId}/returnrequests/${returnRequestId}/items/${editingItem?.id}`,
      {
        ndc: formattedNdc,
        description: data.description,
        name: "name",
        manufacturer: data.manufacturer,
        packageSize: data.pkgSize,
        gtin14: data.gtin14,
        serialNumber: data.serialNumber,
        controlledSubstanceCode: data.controlledSubstanceCode,
        requestType: "typeofrequest",
        fullQuantity: data.quantityType === "full" ? data.quantity : "0",
        partialQuantity: data.quantityType === "partial" ? data.quantity : "0",
        strength: data.strength,
        dosage: data.dosage,
        status: "PENDING",
        expirationDate: data.expDate,
        lotNumber: data.lot,
      }
    )
      .then((response) => {
        queryClient.setQueryData(
          "items",
          items.map((item: any) =>
            item.id === editingItem?.id ? response.data : item
          )
        );
      })
      .then(() => parser.resetParser());
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoadingItems(true);
    if (editingItem) {
      await updateItem(params.requestId, data);
    } else if (params.requestId) {
      await addItem(params.requestId, data);
    } else {
      createReturnRequestApi().then((response) => {
        addItem(response.id, data);
      });
    }
    setLoadingItems(false);
    reset({
      ndc: "",
      fullQty: "",
      partialQty: "",
      expDate: "",
      lot: "",
      description: "",
      manufacturer: "",
      strength: "",
      dosage: "",
      pkgSize: "",
      gtin14: "",
      serialNumber: "",
      controlledSubstanceCode: "",
    });
    ndcRef.current?.focus();
  });

  const quantity = watch("quantity");
  const quantityType = watch("quantityType");

  useEffect(() => {
    if (!quantity) {
      setPrice(0);
      setValue("price", "");
    } else if (quantityType && productData) {
      const newPrice =
        quantityType === "full"
          ? quantity * productData.Package_Price
          : quantity * productData.Unit_Price;
      setPrice(newPrice);
      setValue("price", newPrice);
    }
  }, [quantity, quantityType, productData, setValue]);

  const deleteItem = async ({ id }: any) => {
    setLoadingItems(true);
    await deleteMethod(
      `/pharmacies/${params.pharmacyId}/returnrequests/${params.requestId}/items/${id}`
    );
    setLoadingItems(false);
    return id;
  };
  const { mutate } = useMutation(deleteItem, {
    onSuccess: (id) => {
      const newData = [...items];
      newData.forEach((item, i) => {
        if (item.id === id) {
          newData.splice(i, 1);
          return;
        }
      });
      queryClient.setQueryData("items", newData);
    },
  });

  const handleEditItem = (item: any) => {
    setEditingItem(item);

    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }

    let quantityTypeValue = "";
    let quantityValue = 0;

    if (item.fullQuantity !== 0) {
      quantityTypeValue = "full";
      quantityValue = item.fullQuantity;
    } else if (item.partialQuantity !== 0) {
      quantityTypeValue = "partial";
      quantityValue = item.partialQuantity;
    }
    setValue("ndc", item.ndc);
    setValue("quantityType", quantityTypeValue);
    setValue("quantity", quantityValue);
    setValue("expDate", item.expirationDate);
    setValue("lot", item.lotNumber);
    setValue("gtin14", item.gtin14);
    setValue("serialNumber", item.serialNumber);
  };

  const rows: Array<TableRowProps> = items
    ? createItemsRows(items, mutate, returnRequest?.returnRequestStatus, handleEditItem)
    : [];

  const send = async () => {
    setLoadingItems(true);
    history.push(
      `/pharmacies/${params.pharmacyId}/returnrequests/${params.requestId}/items`
    );
    setLoadingItems(false);
  };

  const handleClickOnCreateShipment = async () => {
    await putMethod(
      `/pharmacies/${params.pharmacyId}/returnrequests/${params.requestId}/send`
    );
    history.push(
      `/pharmacies/${params.pharmacyId}/returnrequests/${params.requestId}/shipments/create`
    );
  };

  useEffect(() => {
    if (parser.shouldParserExit() && output.length > 30) {
      const message = output.join("-");
      toastInfoDelayedClose(message);
      const newParser = new Parser(fields);
      clearFormFields();
      output.forEach((char) => newParser.parseChar(char));
      const results = newParser.getFields();
      results.forEach(({ fieldName, data }) => {
        if (fieldName === "LOT") {
          setValue("lot", data);
        } else if (fieldName === "GTIN") {
          const ndc = extractNDC(data);
          setValue("ndc", ndc?.data);
        } else if (fieldName === "EXP") {
          const formattedDate = formatDate(data);
          setValue("expDate", formattedDate);
        }
      });
    }
  }, [output, parser, setValue, clearFormFields, userRole]);

  return loadingReturn ? (
    <Loader />
  ) : (
    <div>
      <Container>
        {returnRequest?.returnRequestStatus !== "SENT" && (
          <form onSubmit={onSubmit}>
            <CardsWrapper ref={targetRef}>
              <ItemEntry>
                <ImageWrapper style={{ paddingRight: "0.8rem" }}>
                  <img src={AddItemImage} alt="login" />
                </ImageWrapper>
                <FormMolecule
                  label="Item Entry"
                  control={control}
                  list={itemEntry}
                  onInputFieldKeyDown={(
                    e: React.KeyboardEvent<HTMLDivElement>,
                    controlingName: string
                  ) => {
                    if (controlingName === "quantity") {
                      const isDigit = /^\d$/.test(e.key);
                      const isPrintable = e.key.length === 1;

                      if (!isDigit && isPrintable) {
                        e.preventDefault();
                        return;
                      }
                    }
                    if (parser.shouldParserExit()) return;
                    setOutput((prevState) => [...prevState, e.key]);
                    parser.quickScan(e.key);
                  }}
                  ndcRef={ndcRef}
                />
              </ItemEntry>
              {loading ? (
                <Loader />
              ) : (
                <Product>
                  <FormMolecule
                    label="Product details"
                    control={control}
                    list={productDetails}
                  />
                </Product>
              )}
            </CardsWrapper>
            <div className={classes.buttonWrapper}>
              <CallToActionButton
                type="submit"
                height={"70px"}
                text={editingItem ? "Update Item" : "Add Item"}
                width={largeButton.width}
              />
            </div>
          </form>
        )}
        {loadingItems ? (
          <Loader />
        ) : (
          rows.length > 0 && (
            <div ref={itemsRef}>
              <TableOrganism
                header={
                  returnRequest?.returnRequestStatus === "DRAFT" ||
                  returnRequest?.returnRequestStatus !== "SENT"
                    ? createHeaderRow(headerTitles)
                    : createHeaderRow(
                        headerTitles.slice(0, headerTitles.length - 1)
                      )
                }
                rows={rows}
              />
              {returnRequest?.returnRequestStatus !== "SENT" && (
                <ButtonsWrapper>
                  <ButtonWrapper className={classes.marginInBetween}>
                    <BigRedButton
                      version={ButtonVersion.SMALL}
                      text={"Submit and Print"}
                      type=""
                      onClick={send}
                    />
                  </ButtonWrapper>
                </ButtonsWrapper>
              )}
            </div>
          )
        )}
        <div className={classes.buttonWrapper2}>
          <CallToActionButton
            height={"70px"}
            text={"Create UPS Label"}
            width={largeButton.width}
            backgroundStyle="transparent linear-gradient(90deg, #323A87 0%, #211D57 100%) 0% 0% no-repeat padding-box"
            onClick={handleClickOnCreateShipment}
            disabled={isRoleIncluded([UserRole.Warehouse], userRole)}
          />
        </div>
      </Container>
    </div>
  );
};

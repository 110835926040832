import { LandingPage } from "../pages/landingpage/LandingPage";
import { CallUsPage } from "../pages/callUs/CallUsPage";
import { AccountManagmentPage } from "../pages/accountManagment/AccountManagmentPage";
import { AddItemPage } from "../pages/addItem/AddItemPage";
import { CreateAccount } from "../pages/createAccount/CreateAccount";
import { ReturnPage } from "../pages/return/ReturnPage";
import { VerifiedItems } from "../pages/verifiedItems/VerifiedItems";
import { PharmacyProfilePage } from "../pages/pharmacyProfile/PharmacyProfilePage";
import IRoute from "./interfaces/route";
import SignInPage, { SignIn } from "../pages/signIn/SignInPage";
import { RegisterPage } from "../pages/register/RegisterPage";
import AboutUsPage from "../pages/aboutUs/AboutUsPage";
import ComingSoonPage from "../pages/comingSoon/ComingSoonPage";
import { CompliancePage } from "../pages/compliance/CompliancePage";
import { ContactUsPage } from "../pages/contactUs/ContactUsPage";
import RequestNewPassword from "../pages/requestNewPassword/RequestNewPassword";
import { ForgotPassword } from "../pages/forgotPassword/ForgotPassword";
import AdminLogin from "../pages/AdminLogin/AdminLoginPage";
import AdminTablePage from "../pages/adminTable/AdminTablePage";
import { OurServicesPage } from "../pages/our services/OurServicesPage";
import AdminTableRequestsPage from "../pages/adminTableRequests/AdminTableRequestsPage";
import AdminTableApprovePage from "../pages/adminTableApprove/AdminTableApprovePage";
import CheckToken from "../pages/checkToken/CheckToken";
import CreateReturnPage from "../pages/createReturn/CreateReturnPage";
import Calendar from "../pages/calendarPage/CalendarPage";
import AdminForgetPassword from "../pages/AdminForgetPassword/AdminForgetPasswordPage";
import ReportsPage from "../pages/reports/ReportsPage";
import LabelsPage from "../pages/upsLabels/LabelsPage";
import LabelPage from "../pages/upsLabels/LabelPage";
import CreateLabelPage from "../pages/createUpsLabel/CreateLabelPage";
import ActivateAccountPage from "../pages/ActivateAccount/ActivateAccountPage";
import AdminAddPharmacyPage from "../pages/adminAddPharmacy/AdminAddPharmacyPage";
import WholesalerUsersListPage from "../pages/adminWholesalerUsersList/WholesalerUsersListPage";
import CreateWholesalerUserPage from "../pages/adminCreateWholesalerUser/CreateWholesalerUserPage";
import WholesalerUserLoginPage from "../pages/wholesalerUserLogin/WholesalerUserLoginPage";
import TestPage from "../pages/TestPage/TestPage";
import { NotificationsPage } from "../pages/notifications/NotificationsPage";

const routes: IRoute[] = [
  {
    path: "/",
    name: "Landing Page",
    component: LandingPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/signin",
    name: "Sign In",
    component: SignInPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/register",
    name: "Registration",
    component: RegisterPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/wholesaler-user/login",
    name: "Wholesaler User Login",
    component: WholesalerUserLoginPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/about",
    name: "Who Are We?",
    component: AboutUsPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/compliance",
    name: "Compliance",
    component: ComingSoonPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/services",
    name: "What Do We Do ?",
    component: OurServicesPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/contact",
    name: "Contact Info & Details",
    component: ContactUsPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/callus",
    name: "",
    component: CallUsPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/pharmacymanagement",
    name: "Pharmacy Management",
    component: AccountManagmentPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/:userId/pharmacy/management",
    name: "Pharmacy Management",
    component: AccountManagmentPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/wholesaler-user/pharmacymanagement",
    name: "Pharmacy Management",
    component: AccountManagmentPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pharmacies/:pharmacyId/returnrequests/add",
    name: "Return Request",
    component: AddItemPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pharmacies/:pharmacyId/returnrequests/:requestId/add",
    name: "Return Request",
    component: AddItemPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/pharmacies/:pharmacyId/returnrequests/add",
    name: "requestId",
    component: AddItemPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/pharmacies/:pharmacyId/returnrequests/:requestId/add",
    name: "requestId",
    component: AddItemPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pharmacies/:pharmacyId/date",
    name: "Preferred Date",
    component: Calendar,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/pharmacies/:pharmacyId/date",
    name: "Preferred Date",
    component: Calendar,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pharmacy/add",
    name: "Add Pharmacy",
    component: CreateAccount,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/users/:userId/pharmacy/add",
    name: "Add Pharmacy",
    component: CreateAccount,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pharmacies/:pharmacyId/returns",
    name: "Returns",
    component: ReturnPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/all/pharmacies/returns",
    name: "Returns",
    component: ReturnPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/pharmacies/:pharmacyId/returns",
    name: "Returns",
    component: ReturnPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/wholesaler-user/pharmacies/:pharmacyId/returns",
    name: "Returns",
    component: ReturnPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pharmacies/:pharmacyId/returnrequests/:requestId/items",
    name: "Items",
    component: VerifiedItems,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/wholesaler-user/pharmacies/:pharmacyId/returnrequests/:requestId/items",
    name: "Items",
    component: VerifiedItems,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pharmacies/:pharmacyId",
    name: "Pharmacy",
    component: PharmacyProfilePage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/pharmacies/:pharmacyId",
    name: "Pharmacy",
    component: PharmacyProfilePage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/wholesaler-user/pharmacies/:pharmacyId",
    name: "Pharmacy",
    component: PharmacyProfilePage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/password/changePassword",
    name: "Check Token",
    component: CheckToken,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/password/resetPassword",
    name: "Reset Password",
    component: RequestNewPassword,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    component: ForgotPassword,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/admin/forget/password",
    name: "forget password",
    component: AdminForgetPassword,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/admin/login", // '/admin' does not work but was preferred
    name: "Adminstrator",
    component: AdminLogin,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/admin/home",
    name: "Home",
    component: AdminTablePage,
    exact: true,
    isPrivate: true,
  },
  //Ask Samer about the following misleading info -Ghazala
  {
    path: "/admin/pharmacies/:pharmacyId/returnrequests/:requestId/items",
    name: "New Requests",
    component: AdminTableRequestsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pharmacies/:pharmacyId/returnrequests/:requestId/itemsPage",
    name: "New Requests",
    component: AdminTableRequestsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/approve/returns/:pharmacyId",
    name: "Approve Returns",
    component: AdminTableApprovePage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pharmacies/:pharmacyId/returnrequest/create",
    name: "Create Return Request",
    component: CreateReturnPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pharmacies/:pharmacyId/returnrequests/:requestId/reports",
    name: "Reports",
    component: ReportsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/pharmacies/:pharmacyId/returnrequests/:requestId/reports-types",
    name: "Reports",
    component: ReportsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/wholesaler-user/pharmacies/:pharmacyId/returnrequests/:requestId/reports",
    name: "Reports",
    component: ReportsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pharmacies/:pharmacyId/returnrequests/:requestId/shipments/create",
    name: "Create UPS Shipment",
    component: CreateLabelPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/pharmacies/:pharmacyId/returnrequests/:requestId/shipments/create",
    name: "Create UPS Shipment",
    component: CreateLabelPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pharmacies/:pharmacyId/returnrequests/:requestId/shipments",
    name: "UPS Shipments",
    component: LabelsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/pharmacies/:pharmacyId/returnrequests/:requestId/shipments",
    name: "UPS Shipments",
    component: LabelsPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/pharmacies/:pharmacyId/returnrequests/:requestId/shipments/:shipmentId/label",
    name: "UPS Label",
    component: LabelPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/pharmacies/:pharmacyId/returnrequests/:requestId/shipments/:shipmentId/label",
    name: "UPS Label",
    component: LabelPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/activate-account",
    name: "Activate Account",
    component: ActivateAccountPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: "/admin/pharmacy/add",
    name: "Add Pharmacy",
    component: AdminAddPharmacyPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/wholesaler-users",
    name: "Wholesaler Users",
    component: WholesalerUsersListPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/wholesaler-users/create",
    name: "Create Wholesaler User",
    component: CreateWholesalerUserPage,
    exact: true,
    isPrivate: true,
  },
  {
    path: "/admin/notifications",
    name: "Notifications",
    component: NotificationsPage,
    exact: true,
    isPrivate: true,
  },
  // {
  //   path: "/testing",
  //   name: "Test Page",
  //   component: TestPage,
  //   exact: true,
  //   isPrivate: false
  // },
];

export default routes;
